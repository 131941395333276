// ./
import dataFormElements from './dataform.elements'
import { parseCompaniesResponse, parseStackedCompaniesResponse } from './response.parser'
import { parseStoragesResponse, parseStackedStoragesResponse } from './response.parser'

// Classes
import { JoiManager }  from '@/Classes/Network/JoiManager'
import { DataParsers } from '@/Classes/Responses/DataParsers'
import { ValueTools }  from '@/Classes/Static/ValueTools'
import { VuexTools }   from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader     from '@/Components/Global/BasicHeader/template.vue'
import DataTable       from '@/Components/Global/DataTable/template.vue'
import SideMenu        from '@/Components/Global/SideMenu/template.vue'
import AdminNavigation from '@/Components/Modules/2/AdminNavigation/template.vue'
import PopupUserForm   from '@/Components/Modules/2/PopupUserForm/template.vue'

// Components (Refs)
import { BasicHeaderRef }   from '@/Components/Global/BasicHeader/component'
import { DataFormRef }      from '@/Components/Modules/2/DataForm/component'
import { DataTableRef }     from '@/Components/Global/DataTable/component'
import { SideMenuRef }      from '@/Components/Global/SideMenu/component'
import { PopupUserFormRef } from '@/Components/Modules/2/PopupUserForm/component'

// Constants
import { AppValues }   from '@/Constants/Global/AppValues'
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Component }   from '@/Constants/Global/Component'
import { Documents }   from '@/Constants/Global/Documents'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'
import { Module2 }     from '@/Constants/Modules/Module2'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View22 = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Modules.View22.NAME,

	components: {
		AdminNavigation,
		BasicHeader,
		SideMenu,
		PopupUserForm,
		DataTable
	},

	data: function() {
		return {
			dataForms: {
				['Companies']: dataFormElements.Companies,
				['Storages']: dataFormElements.Storages
			},
			sideMenu: {
				items: [
					{ type: 'button', label: 'Clientes', componentType: 'PopupUserForm', componentName: 'Companies', icon: 'building', isActive: true },
					{ type: 'button', label: 'Ubicaciones', componentType: 'PopupUserForm', componentName: 'Storages', icon: 'warehouse', isActive: false }
				]
			},
			states: {
				componentType: 'PopupUserForm',
				componentName: 'Companies',
				dataFormAction: Component.Actions.INSERT,
				dataTableParser: parseCompaniesResponse,
				documentToUpdate: undefined,
				inputButtonKey: undefined,
				isMobile: false,
				showDataTable: true,
				showPopupUserForm: false
			}
		}
	},

	mounted: function() {
		this._initDataTable()
		this._initDataForm()
		this._initPermissions()

		// Deshabilitar comportamiento de Busqueda Local y Tipo de Descarga para el componente 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({ exportDataAsAsync: true, exportDataFilterType: AppValues.DataTableExportDataFilterType.QUANTITY, isLocalSearch: false })

		// Establecer las propiedades para el componente <SideMenu>.
		this._sideMenu.initialize(this.sideMenu)
		this._sideMenuMobile.initialize(this.sideMenu)
		this._sideMenuMobile.setStates<SideMenuRef['states']>({ isMobile: true})

		// Componente PopupTable/DataTable
		this._nestedDataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
		this._nestedDataTable.setSelectable(true)

		// Component View22/DataTable
		this._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_dataForm: function(): DataFormRef {
			return this._popupUserForm.$refs.dataForm as DataFormRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_nestedDataTable: function(): DataTableRef {
			return this._popupUserForm._dataForm._popupTable._dataTable as DataTableRef
		},

		_popupUserForm: function(): PopupUserFormRef {
			return this.$refs.popupUserForm as PopupUserFormRef
		},

		_sideMenu: function(): SideMenuRef {
			return this.$refs.sideMenu as SideMenuRef
		},

		_sideMenuMobile: function(): SideMenuRef {
			return this.$refs.sideMenuMobile as SideMenuRef
		}
	},

	methods: {
		_initCommunes: async function(region: string) {
			// Limpiar los arrays para evitar duplicidad de opciones.
			const { _dataForm } = this._popupUserForm
			_dataForm.clearOptions('commune')

			// Generar las opciones de los <Select> de las comunas.
			const response = await Store.dispatch('fetchCommunes', { region })
			if (response) {
				response.sort().forEach((x: any) => {
					_dataForm.addOption('commune', { value: x, text: x })
				})
			}
		},

		_initDataForm: function() {
			const { componentName } = this.states
			const { _dataForm } = this._popupUserForm
			_dataForm.initialize(dataFormElements[componentName])
		},

		_initDataTable: async function(page = 1, forceRefresh = false) {
			if (forceRefresh) this._dataTable.clearAll()

			// Respuesta de la Acción donde se obtienen los Registros.
			const response = await Store.dispatch(`fetch${ this.states.componentName }ForPage`, { forceRefresh, page })

			// Procesar la información y actualizar el Componente.
			const { fields, items, actions } = this.states.dataTableParser(response.data)
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
		},

		_initPermissions: function() {
			const { componentName } = this.states
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject

			switch (componentName) {
				case 'Companies':
					this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_COMPANIES?.privileges.write)
					this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_COMPANIES?.privileges.write)
					break
				case 'Storages':
					this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_STORAGES?.privileges.write)
					this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_STORAGES?.privileges.write)
					break
			}
		},

		_initRegions: function() {
			// Generar las opciones de los <Select> si existen regiones en la Store.
			const { _dataForm } = this._popupUserForm
			const regions = Store.getters.getStoredRegions
			_dataForm.clearOptions('commune')

			if (regions !== undefined) {
				// Limpiar los arrays para evitar duplicidad de opciones.
				_dataForm.clearOptions('region')
				regions.forEach((x: any) => {
					_dataForm.addOption('region', { value: x.regiones, text: x.regiones })
				})
			}
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.XLarge })
			this._nestedDataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })

			this.setStates<View22Ref['states']>({ isMobile: breakpoint <= Breakpoints.Medium, dataTableParser: this._resolveDataTableParser(breakpoint <= Breakpoints.XLarge) })
			this._initDataTable()
			this._updatePopupComponents(true)
		},

		_resolveCompaniesPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.Companies.AddCompany
				: Server.Routes.Companies.UpdateCompany
		},

		_resolveDataTableParser: function(stackedOrDefault: boolean) {
			const { componentName } = this.states
			switch (componentName) {
				case 'Companies':
					return stackedOrDefault ? parseStackedCompaniesResponse : parseCompaniesResponse
				case 'Storages':
					return stackedOrDefault ? parseStackedStoragesResponse : parseStoragesResponse
			}
		},

		_resolveStoragesPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.Storages.AddStorage
				: Server.Routes.Storages.UpdateStorage
		},

		_updatePopupComponents: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Propiedades.
			const { _dataForm } = this._popupUserForm
			const key = this.states.inputButtonKey

			// Validaciones Opcionales según Casos.
			if (!preventShowPopupTable) _dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			if (forceRefresh) this._nestedDataTable.clearAll()
			this._nestedDataTable.setFetchingState()

			if (key === 'company') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Selección Empresa')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyCompanies')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchCompaniesForPage', { forceRefresh, page })
				const SummaryParser = DataParsers.Companies.GetSummaryParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data.filter((x: any) => x.type))
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('name', 'asc')
			}
			else if (key === 'technical' || key === 'userAdmin') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle(`Selección de ${ key === 'technical' ? 'Técnico' : 'Administrador de Contrato' }`)

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyUsers')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchUsersForPage', { forceRefresh, isClient: false, page, type: 'Internal' })
				const SummaryParser = DataParsers.Users.GetSummaryInternalsParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data.filter((x: any) => x._idRole !== Documents.Roles.Kreis.CLIENT && x.isValid !== false))
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('rut', 'asc')
			}
		},

		_upsertCompany: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { _dataForm }        = this._popupUserForm
			const { action }           = _dataForm.states
			const isInserting          = action === Component.Actions.INSERT

			// Realizar validación de los Campos.
			if (!_dataForm.validateStates()) {
				this.showToast('Error de Validación', 'Verifica que ningun campo se encuentre marcado de color rojo.', 'danger')
				return
			}

			// Inputs del DataForm.
			const _idAdminCompany = Store.getters.getStoredUser._idAdminCompany
			const _idCompany      = documentToUpdate?._idCompany
			const code            = _dataForm.getValue('code')
			const rut             = _dataForm.getValue('rut')
			const name            = _dataForm.getValue('name')
			const address         = _dataForm.getValue('address')
			const commune         = _dataForm.getValue('commune')
			const region          = _dataForm.getValue('region')
			const type            = _dataForm.getValue('type')[0]
			const isClient        = _dataForm.getValue('isClient') === 'Sí'
			const isValid         = _dataForm.getValue('isValid') === 'Sí'
    
			// Objeto con las Propiedades requeridas por la Petición.
			const body = action === Component.Actions.INSERT ? {
				_idAdminCompany, code, rut, name, address, region, commune, type, isClient
			} : {
				_idCompany, name, address, commune, region, isValid
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module2.M22.JoiSchemas.AddCompany : Module2.M22.JoiSchemas.UpdateCompany
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear una' : 'Actualizar la' } Empresa`, result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = action === Component.Actions.INSERT ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.doFetch({ action: fetchAction, path: this._resolveCompaniesPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				const { fields, items, actions } = this.states.dataTableParser(response.data.body)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)

				if (action === Component.Actions.INSERT) {
					Store.commit('addCompany', response.data.body[0])
					this._dataTable.addRow(items[0])
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					Store.commit('updateCompany', response.data.body[0])
					this._dataTable.updateRow(items[0], '_idCompany')
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}

				this.onClose()
				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}
		},

		_upsertStorage: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { _dataForm }        = this._popupUserForm
			const { action }           = _dataForm.states
			const isInserting          = action === Component.Actions.INSERT
			
			// Inputs del DataForm.
			const _idStorage   = documentToUpdate?._idStorage
			const _idCompany   = _dataForm.getValue('company', '_id')
			const _idTechnical = _dataForm.getValue('technical', '_id') || null
			const _idUserAdmin = _dataForm.getValue('userAdmin', '_id') || null
			const codeLocation = _dataForm.getValue('codeLocation')
			const code         = _dataForm.getValue('code')
			const name         = _dataForm.getValue('name')
			const address      = _dataForm.getValue('address')
			const region       = _dataForm.getValue('region')  || null
			const commune      = _dataForm.getValue('commune') || null
			const isValid      = _dataForm.getValue('isValid') === 'Sí'

			// Objeto con las Propiedades requeridas por la Petición.
			const body = action === Component.Actions.INSERT ? {
				_idCompany, _idTechnical, _idUserAdmin, codeLocation, code, name, address, commune, region
			} : {
				_idStorage, _idCompany, _idTechnical, _idUserAdmin, code, name, address, region, commune, isValid
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module2.M22.JoiSchemas.AddStorage : Module2.M22.JoiSchemas.UpdateStorage
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear una' : 'Actualizar la' } Ubicación`, result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = action === Component.Actions.INSERT ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.doFetch({ action: fetchAction, path: this._resolveStoragesPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				const { fields, items, actions } = this.states.dataTableParser(response.data.body)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)

				if (action === Component.Actions.INSERT) {
					Store.commit('addStorage', response.data.body[0])
					this._dataTable.addRow(items[0])
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					Store.commit('updateStorage', response.data.body[0])
					this._dataTable.updateRow(items[0], '_idStorage')
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}

				this.onClose()
				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}
		},

		onButtonClick: function(componentType: string, componentName: string) {
			this.setStates<View22Ref['states']>({ componentName, componentType })
			this._initDataForm()

			this._dataTable.clearSortBy()
			this.setStates<View22Ref['states']>({ dataTableParser: this._resolveDataTableParser(this._dataTable.states.stacked) })
			this._dataTable.setPaginationPage(1)
		},

		onClose: function() {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			const { _dataForm } = this._popupUserForm
			this.setStates<View22Ref['states']>({ showPopupUserForm: false })
			_dataForm.clearInputs()
		},
		
		onDataFormSubmit: function() {
			const { componentName } = this.states
			switch (componentName) {
				case 'Companies': return this._upsertCompany()
				case 'Storages': return this._upsertStorage()
			}
		},

		onDTButtonClick: async function(key: string, row: any) {
			if (key === 'edit') {
				// Almacenar en la propiedad 'documentToUpdate' la fila a actualizar.
				this.setStates<View22Ref['states']>({ dataFormAction: Component.Actions.UPDATE, documentToUpdate: row.item, showPopupUserForm: true })
				const { componentName, documentToUpdate } = this.states
				
				// Cambiar los estados a 'update' y la visibilidad en 'true'
				const { _dataForm } = this._popupUserForm
				_dataForm.setStates<DataFormRef['states']>({ action: Component.Actions.UPDATE })

				// Generar las Regiones.
				this._initRegions()

				// Array con valores no validos para comparar el contenido del campo correspondiente,
				// y evitar establecer valores no deseados en el componente 'DataForm'.
				const invalidValues = [undefined, null, '', AppValues.Strings.DEFAULT_EMPTY_STRING]

				// Generar las opciones de los <Select> de las comunas.
				const { region } = documentToUpdate
				if (!ValueTools.isEqualTo(region, invalidValues)) await this._initCommunes(region)

				if (componentName === 'Companies') {
					// Autocompletar los Inputs al momento de editar.
					for (let propKey of ['code', 'rut', 'name', 'address', 'isClient', 'isValid']) {
						const value = documentToUpdate[propKey]
						if (!ValueTools.isEqualTo(value, invalidValues)) {
							_dataForm.setValue(propKey, value)
						}
					}

					// La propiedad 'Type', se debe completar según el Primer Caracter.
					const { type } = documentToUpdate
					const optionType = type === 'C' ? 'Cliente' : type === 'P' ? 'Proveedor' : 'Sucursal Interna'
					_dataForm.setValue('type', optionType)

					// La Región y la Comuna deben ser establecidos manualmente al funcionar dentro de un 'Select'.
					const { commune } = documentToUpdate
					if (!ValueTools.isEqualTo(region, invalidValues) && !ValueTools.isEqualTo(commune, invalidValues)) {
						_dataForm.setValue('region', region)
						_dataForm.setValue('commune', commune)
					}
				}
				else if (componentName === 'Storages') {
					// Autocompletar los Inputs al momento de editar.
					for (let key of ['codeLocation', 'code', 'name', 'address', 'isValid']) {
						const value = documentToUpdate[key]
						if (!ValueTools.isEqualTo(value, invalidValues)) {
							_dataForm.setValue(key, value)
						}
					}

					// Autocompletar los InputsButtons al momento de editar.
					for (let key of ['company', 'technical', 'userAdmin']) {
						const _idKey = `_id${ key[0].toUpperCase() + key.slice(1) }`
						const _idValue = documentToUpdate[_idKey]
						const stringValue = documentToUpdate[key]
						if (!ValueTools.isEqualTo(_idValue, invalidValues)) _dataForm.setValue(key, _idValue, '_id')
						if (!ValueTools.isEqualTo(stringValue, invalidValues)) _dataForm.setValue(key, stringValue, 'value')
					}

					// La Región y la Comuna deben ser establecidos manualmente al funcionar dentro de un 'Select'.
					const { commune } = documentToUpdate
					if ((!ValueTools.isEqualTo(region, invalidValues)) && (!ValueTools.isEqualTo(commune, invalidValues))) {
						_dataForm.setValue('region', region)
						_dataForm.setValue('commune', commune?.toUpperCase())
					}
				}
			}
		},

		onDTCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			this.onDTSearchButtonClicked(searchParams, page)
		},

		onDTExportDataClick: async function(format: string, filterType: AppValues.DataTableExportDataFilterType, filterValue: AppValues.QuantityChoiceList) {
			// Datos requeridos para obtener los Registros a Descargar.
			const { _idAdminCompany } = Store.getters.getStoredUser
			const { componentName } = this.states

			// Objeto con las Propiedades requeridas por la Petición.
			const params = {
				_idAdminCompany,
				itemsPerPage: 1,		// 'itemsPerPage' es Requerido en la Petición pero no es considerada.
				page: 1,				// 'page' es Requerido en la Petición pero no es considerada.
				quantity: filterValue
			}

			// 'Parser' utilizado para procesar la Respuesta de los Datos.
			const { dataTableParser } = this.states
			let response;

			// Realizar la Petición al servidor.
			if(componentName =='Companies'){
				 response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Companies.GetCompaniesByPage, params })
			}
			if(componentName=='Storages'){
				response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Storages.GetStoragesByPage, params })

			}

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body.data
				const { items } = dataTableParser(_response)
				this._dataTable.handleExportDataType(format, items)
			}
		},

		onDTPaginationChanged: async function(page: number) {
			this._initDataTable(page)
		},

		onDTRefreshButtonClick: async function(currentPage: number) {
			Store.commit(`destroy${ this.states.componentName }`)
			this._initDataTable(currentPage, true)
		},

		onDTSearchButtonClicked: function(searchParams: any, page = 1) {
			const { searchKey, searchValue } = searchParams
			const { componentName } = this.states
			const { _idAdminCompany } = Store.getters.getStoredUser

			// Asignar los parametros por pestaña.
			switch (componentName) {
				case 'Companies': {
					this._dataTable.doInputSearch(Server.Routes.Companies.GetCompaniesBySearchFilter,
						this.states.dataTableParser,
						{
							_idAdminCompany, isClient: true,
							itemsPerPage: this._dataTable.itemsPerPage, page,
							searchKey, searchValue
						},
						(response) =>  response.data.body[0]
					)
					break
				}
				case 'Storages': {
					this._dataTable.doInputSearch(Server.Routes.Storages.GetStoragesBySearchFilter,
						this.states.dataTableParser,
						{
							_idAdminCompany,
							itemsPerPage: this._dataTable.itemsPerPage, page,
							searchKey, searchValue
						},
						(response) =>  response.data.body[0]
					)
					break
				}
			}
		},

		onInputButtonClick: function(key: string) {
			this.setStates<View22Ref['states']>({ inputButtonKey: key })
			this._updatePopupComponents(false)
		},

		onOptionChange: function(key: string, name: string) {
			if (key === 'region') {
				this._initCommunes(name)
			}
		},

		onPTSelect: function(key: string, currentRow: any) {
			const { _dataForm } = this._popupUserForm
			const keyId = key === 'company' ? '_idCompany' : '_idUser'
			_dataForm.setValue(key, currentRow.item[keyId], '_id')
			_dataForm.setValue(key, currentRow.item.name, 'value')
		},

		onPUFPaginationChanged: function(page: number) {
			this._updatePopupComponents(false, page)
		},

		onPUFRefreshButtonClick: function(key: string) {
			this._updatePopupComponents(false, 1, true)
		},

		onServerCaughtFetchException: function(path: string, resolution: Server.Fetching.Resolutions, status: number, extras?: any) {
			// Controlar las excepciones de las diferentes peticiones realizadas en la vista.
			if (path === Server.Routes.Companies.GetCompanies) {
				this.showToast('Sin Registros', 'No existen Empresas para mostrar!', 'danger')
			}
			else if (path === Server.Routes.Storages.GetStorages) {
				this.showToast('Sin Registros', 'No existen Ubicaciones para mostrar!', 'danger')
			}
			else if (path === Server.Routes.Users.GetUsers) {
				this.showToast('Sin Registros', 'No existen Usuarios para mostrar!', 'danger')
			}
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		},

		onServerFailedResponse: function(path: string) {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		},

		onShowPopupUserForm: function() {
			this.setStates<View22Ref['states']>({ dataFormAction: Component.Actions.INSERT, showPopupUserForm: true })
			this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: this.states.dataFormAction })
			this._initRegions()
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions')
	}
})

// Exports
export default View22
export type View22Ref = InstanceType<typeof View22>